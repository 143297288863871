import request from '@/utils/request'

// 手机号验证码登录
export const pclogin = ({ phone, code }) => {
  return request({
    url: '/index.php/api/Loginp/pclogin',
    method: 'post',
    data: {
      phone,
      code
    }
  })
}
// 发送验证码
export const sendPhone = ({ phone, status }) => {
  return request({
    url: '/index.php/api/Loginp/sendPhone',
    method: 'post',
    data: {
      phone,
      status
    }
  })
}

// 获取用户信息
export const getUserInfo = () => {
  return request({
    url: '/index.php/api/User/getUserInfo',
    method: 'post'
  })
}

// 发布需求
export const release = ({
  sType,
  uid,
  title,
  field,
  paid,
  details,
  cooperation,
  submission,
  bidders,
  single,
  information,
  urgent,
  state,
  remarks,
  content

}) => {
  return request({
    url: '/index.php/api/Release/release',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      sType,
      uid,
      title,
      field,
      paid,
      details,
      cooperation,
      submission,
      bidders,
      single,
      information,
      urgent,
      state,
      remarks,
      content

    }
  })
}

// 地区列表
export const getAddressList = () => {
  return request({
    url: '/index.php/api/Release/getAddressList',
    method: 'post'
  })
}

// 任务大厅列表
export const planningScheme = ({
  pageNum,
  pageSize,
  state,
  cooperation,
  mind,
  urgent,
  min_price,
  max_price,
  keyWord
}) => {
  return request({
    url: '/index.php/api/Home/planningScheme',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      state,
      cooperation,
      mind,
      urgent,
      min_price,
      max_price,
      keyWord
    }
  })
}

// 方案大厅列表
export const schemeHall = ({
  pageNum,
  pageSize,
  state,
  cooperation,
  mind,
  min_price,
  max_price,
  keyWord
}) => {
  return request({
    url: '/index.php/api/Home/schemeHall',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      state,
      cooperation,
      mind,
      min_price,
      max_price,
      keyWord
    }
  })
}

// 会员信息
export const rateMembers = () => {
  return request({
    url: '/index.php/api/Vip/rateMembers',
    method: 'post'
  })
}

// 余额支付购买会员
export const balanceVip = ({
  vid,
  money,
  state
}) => {
  return request({
    url: '/index.php/api/Vip_pc/balanceVip',
    method: 'post',
    data: {
      vid,
      money,
      state
    }
  })
}

// 微信付款链接
export const generateQRCode = ({
  vid,
  money,
  state
}) => {
  return request({
    url: '/index.php/api/Vip_pc/generateQRCode',
    method: 'post',
    data: {
      vid,
      money,
      state
    }
  })
}

// 支付宝付款链接
export const pay = ({
  vid,
  money,
  state
}) => {
  return request({
    url: '/index.php/api/Vip_pc/pay',
    method: 'post',
    data: {
      vid,
      money,
      state
    }
  })
}

// 企业审核
export const rpriseReview = ({
  enterpriseName,
  creditCode,
  sineLicense
}) => {
  return request({
    url: '/index.php/api/Vip/rpriseReview',
    method: 'post',
    data: {
      enterpriseName,
      creditCode,
      sineLicense
    }
  })
}

// 开通会员记录
export const vipLog = ({
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/Vip/vipLog',
    method: 'get',
    params: {
      pageNum,
      pageSize
    }
  })
}

// 修改用户信息
export const updUser = ({
  avatar,
  nickname
}) => {
  return request({
    url: '/index.php/api/Login/updUser',
    method: 'post',
    data: {
      avatar,
      nickname
    }
  })
}

// 推荐任务
export const mendedTasks = () => {
  return request({
    url: '/index.php/api/Home/mendedTasks',
    method: 'post'
  })
}

// 我的发榜
export const MyReleaseList = ({
  status,
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/Ranking/MyReleaseList',
    method: 'post',
    data: {
      status,
      pageNum,
      pageSize
    }
  })
}

// 需求详情
export const releaseInfo = ({
  id
}) => {
  return request({
    url: '/index.php/api/Ranking/releaseInfo',
    method: 'get',
    params: {
      id
    }
  })
}

// 揭榜
export const openBoard = ({
  rid,
  reid
}) => {
  return request({
    url: '/index.php/api/Index/openBoard',
    method: 'post',
    data: {
      rid,
      reid
    }
  })
}

// 修改需求
export const updRelease = ({
  state,
  id,
  uid,
  details,
  title,
  field,
  paid,
  cooperation,
  submission,
  bidders,
  single,
  information,
  urgent,
  remarks,
  content
}) => {
  return request({
    url: '/index.php/api/Release/updRelease',
    method: 'post',
    data: {
      state,
      id,
      uid,
      title,
      details,
      field,
      paid,
      cooperation,
      submission,
      bidders,
      single,
      information,
      urgent,
      remarks,
      content
    }
  })
}

// 揭榜中
export const myUnlock = () => {
  return request({
    url: '/index.php/api/My/myUnlock',
    method: 'post'
  })
}

// 完成揭榜
export const myCompleteUnlock = () => {
  return request({
    url: '/index.php/api/My/myCompleteUnlock',
    method: 'post'
  })
}

// 完成揭榜
export const publishResources = ({
  imgs,
  unid,
  title,
  cover,
  cooperation,
  commercial,
  down_price,
  money,
  label,
  remarks,
  file,
  release_id,
  type
}) => {
  return request({
    url: '/index.php/api/My/publishResources',
    method: 'post',
    data: {
      imgs,
      unid,
      title,
      cover,
      cooperation,
      commercial,
      down_price,
      money,
      label,
      remarks,
      file,
      release_id,
      type
    }
  })
}

// 方案每日推荐
export const recdPlan = () => {
  return request({
    url: '/index.php/api/Home/recdPlan',
    method: 'post'
  })
}

// 资源详情
export const myFootDetails = ({ id }) => {
  return request({
    url: '/index.php/api/My/myFootDetails',
    method: 'post',
    data: {
      id
    }
  })
}

// 我的上传
export const myUploadResources = ({ pageNum, pageSize }) => {
  return request({
    url: '/index.php/api/My/myUploadResources',
    method: 'post',
    data: {
      pageNum,
      pageSize
    }
  })
}

// 删除资源
export const delMyResources = ({ id }) => {
  return request({
    url: '/index.php/api/My/delMyResources',
    method: 'post',
    data: {
      id
    }
  })
}

// 我的已购
export const collect = ({ pageNum, pageSize }) => {
  return request({
    url: '/index.php/api/My/collect',
    method: 'post',
    data: {
      pageNum,
      pageSize
    }
  })
}

// 我观看过的
export const myFootprint = ({ pageNum, pageSize }) => {
  return request({
    url: '/index.php/api/My/myFootprint',
    method: 'post',
    data: {
      pageNum,
      pageSize
    }
  })
}

// 删除全部
export const deleteFavorite = () => {
  return request({
    url: '/index.php/api/My/deleteFavorite',
    method: 'post'
  })
}

// 资源是否收藏
export const myCollect = ({ id }) => {
  return request({
    url: '/index.php/api/My/myCollect',
    method: 'post',
    data: {
      id
    }
  })
}

// 身份认证
export const realNameAuth = ({
  frontCard,
  backCard,
  realName,
  idCard,
  phone,
  code
}) => {
  return request({
    url: '/index.php/api/Security/realNameAuth',
    method: 'post',
    data: {
      frontCard,
      backCard,
      realName,
      idCard,
      phone,
      code
    }
  })
}

// 关于我们
export const getContents = ({ id }) => {
  return request({
    url: '/index.php/api/About/getContents',
    method: 'get',
    params: {
      id
    }
  })
}

// 活动列表
export const activityList = ({
  pageNum,
  pageSize,
  keyWord
}) => {
  return request({
    url: '/index.php/api/Index/activityList',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      keyWord
    }
  })
}

// 优秀服务商查看
export const excellent_view = () => {
  return request({
    url: '/index.php/api/Home/excellent_view',
    method: 'post'
  })
}

// 生成微信扫码登录的二维码
export const login = () => {
  return request({
    url: '/index.php/api/Wx_login/login',
    method: 'post'
  })
}

// 移动端二维码获取
export const pc_code = () => {
  return request({
    url: '/index.php/api/Loginp/pc_code',
    method: 'post'
  })
}

// 首页热门任务推荐
export const popular = () => {
  return request({
    url: '/index.php/api/Home/popular',
    method: 'post'
  })
}

// 策划执行
export const planningExecution = ({
  pageNum,
  pageSize,
  keyWord,
  cooperation,
  state
}) => {
  return request({
    url: '/index.php/api/Index/planningExecution',
    method: 'post',
    data: {
      pageNum,
      pageSize,
      keyWord,
      cooperation,
      state
    }
  })
}

// 策划方案
export const UniplanningScheme = ({
  pageNum,
  pageSize,
  keyWord,
  cooperation,
  state
}) => {
  return request({
    url: '/index.php/api/Index/planningScheme',
    method: 'post',
    data: {
      pageNum,
      pageSize,
      keyWord,
      cooperation,
      state
    }
  })
}

// 新任务榜
export const newPlat = ({
  pageNum,
  pageSize,
  keyWord,
  state,
  cooperation,
  min_price,
  max_price
}) => {
  return request({
    url: '/index.php/api/Plan/newPlat',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      keyWord,
      state,
      cooperation,
      min_price,
      max_price
    }
  })
}

// 飙升榜
export const soarPlat = ({
  pageNum,
  pageSize,
  keyWord,
  state,
  cooperation,
  min_price,
  max_price
}) => {
  return request({
    url: '/index.php/api/Plan/soarPlat',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      keyWord,
      state,
      cooperation,
      min_price,
      max_price
    }
  })
}

// 解决问题榜
export const solvePlat = ({
  pageNum,
  pageSize,
  keyWord,
  state,
  cooperation,
  min_price,
  max_price
}) => {
  return request({
    url: '/index.php/api/Plan/solvePlat',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      keyWord,
      state,
      cooperation,
      min_price,
      max_price
    }
  })
}

// 购买方案的微信付款链接
export const hallQRCode = ({ id }) => {
  return request({
    url: '/index.php/api/Hall/hallQRCode',
    method: 'post',
    data: {
      id
    }
  })
}

// 购买方案的支付宝付款链接
export const FApay = ({ id }) => {
  return request({
    url: '/index.php/api/Hall/pay',
    method: 'post',
    data: {
      id
    }
  })
}

// 余额购买资源
export const balancePurchaseResources = ({ id }) => {
  return request({
    url: '/index.php/api/Plan/balancePurchaseResources',
    method: 'post',
    data: {
      id
    }
  })
}

// 榜单详情
export const mentDetails = ({ id }) => {
  return request({
    url: '/index.php/api/Ranking/mentDetails',
    method: 'post',
    data: {
      id
    }
  })
}

// 资源拒绝
export const refuseResourse = ({ unid, reason }) => {
  return request({
    url: '/index.php/api/Ranking/refuseResourse',
    method: 'post',
    data: {
      unid,
      reason
    }
  })
}

// 资源定榜
export const finalizeResourse = ({ unid }) => {
  return request({
    url: '/index.php/api/Ranking/finalizeResourse',
    method: 'post',
    data: {
      unid
    }
  })
}

// 支付合并详情
export const payMerge = ({ id }) => {
  return request({
    url: '/index.php/api/Ranking/payMerge',
    method: 'post',
    data: {
      id
    }
  })
}

// 任务支付总费用的微信付款链接
export const missionQRCode = (data) => {
  return request({
    url: '/index.php/api/Mission/missionQRCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 任务支付总费用的支付宝付款链接
export const MissionPay = (data) => {
  return request({
    url: '/index.php/api/Mission/pay',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 我的收藏
export const collectResources = ({
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/My/collectResources',
    method: 'get',
    params: {
      pageNum,
      pageSize
    }
  })
}

// 真实评论列表
export const getReals = ({
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/About/getReals',
    method: 'get',
    params: {
      pageNum,
      pageSize
    }
  })
}

// 群聊分类
export const getGroupClassList = () => {
  return request({
    url: '/index.php/api/About/getGroupClassList',
    method: 'post'
  })
}

// 群聊详情列表
export const getGroupList = ({
  cId,
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/About/getGroupList',
    method: 'get',
    params: {
      cId,
      pageNum,
      pageSize
    }
  })
}

// 钱包记录查询
export const walletRecords = ({
  start,
  status,
  state,
  pageNum,
  pageSize
}) => {
  return request({
    url: '/index.php/api/Home/walletRecords',
    method: 'post',
    data: {
      start,
      status,
      state,
      pageNum,
      pageSize
    }
  })
}

// 后台审核提现
export const wxWithdrawal = ({
  withdrawal_money
}) => {
  return request({
    url: '/index.php/api/User/wxWithdrawal',
    method: 'post',
    data: {
      withdrawal_money
    }
  })
}

// 提现手续费
export const commssion = () => {
  return request({
    url: '/index.php/api/User/commssion',
    method: 'post'

  })
}

// 余额充值
export const recharge = ({ money }) => {
  return request({
    url: '/index.php/api/Recharge/recharge',
    method: 'post',
    data: {
      money
    }
  })
}
// 58--------

// kimi模型key值
export const kimi = () => {
  return request({
    url: '/index.php/api/Index/kimi',
    method: 'post'
  })
}

// 微信扫码余额充值
export const pcRecharge = ({ money }) => {
  return request({
    url: '/index.php/api/Recharge/pcRecharge',
    method: 'post',
    data: {
      money
    }
  })
}

// kimi模型key值
export const completions = ({
  model,
  messages
}, ation) => {
  return request({
    url: '/v1/chat/completions',
    method: 'post',
    headers: {
      'content-type': 'application/json',
      Authorization: ation
    },
    data: {
      model,
      messages
    }
  })
}

// 修改资源
export const updMyResources = ({
  imgs,
  id,
  title,
  cover,
  cooperation,
  commercial,
  down_price,
  money,
  label,
  remarks,
  file,
  type
}) => {
  return request({
    url: '/index.php/api/My/updMyResources',
    method: 'post',
    data: {
      imgs,
      id,
      title,
      cover,
      cooperation,
      commercial,
      down_price,
      money,
      label,
      remarks,
      file,
      type
    }
  })
}

// pc 合并支付列表
export const pcPayMerge = ({ id }) => {
  return request({
    url: '/index.php/api/Ranking/pcPayMerge',
    method: 'post',
    data: {
      id
    }
  })
}

// 余额支付定榜资源
export const paSynthesis = (data) => {
  return request({
    url: '/index.php/api/Ranking/paSynthesis',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 揭榜滚动列表
export const indexUnveil = () => {
  return request({
    url: '/index.php/api/Index/indexUnveil',
    method: 'post'
  })
}

// pdf 合并
export const pdfMerge = (data) => {
  return request({
    url: '/index.php/api/Ranking/pdfMerge',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 删除过期需求
export const delRelease = ({ id }) => {
  return request({
    url: '/index.php/api/Ranking/delRelease',
    method: 'post',
    data: { id }
  })
}

// 解绑
export const unbindCard = () => {
  return request({
    url: '/index.php/api/Security/unbindCard',
    method: 'post'
  })
}

// 绑定手机号
export const bandPhone = ({
  phone,
  code
}) => {
  return request({
    url: '/index.php/api/User/bandPhone',
    method: 'post',
    data: {
      phone,
      code
    }
  })
}

// 邀请好友记录
export const invitationDetails = () => {
  return request({
    url: '/index.php/api/Home/invitationDetails',
    method: 'post'
  })
}

// 微信扫码登录后的回调
export const getcallback = ({
  code
}) => {
  return request({
    url: '/index.php/api/Wx_login/callback',
    method: 'post',
    data: {
      code
    }
  })
}

// 需求热搜
export const selHotRelease = () => {
  return request({
    url: '/index.php/api/Index/selHotRelease',
    method: 'post'
  })
}

// 资源热搜
export const selHotResound = () => {
  return request({
    url: '/index.php/api/Index/selHotResound',
    method: 'post'
  })
}

// 支付宝扫码余额充值
export const ZFBpay = ({
  money
}) => {
  return request({
    url: '/index.php/api/Recharge/pay',
    method: 'post',
    data: {
      money
    }
  })
}
// 余额充值后的回调
export const getOrderInfo = ({
  order_sn
}) => {
  return request({
    url: '/index.php/api/Recharge/getOrderInfo',
    method: 'post',
    data: {
      order_sn
    }
  })
}

// 购买会员后的回调
export const viPgetOrderInfo = ({
  order_sn
}) => {
  return request({
    url: '/index.php/api/Vip_pc/getOrderInfo',
    method: 'post',
    data: {
      order_sn
    }
  })
}

// 购买资源后的回调
export const HallgetOrderInfo = ({
  order_sn
}) => {
  return request({
    url: '/index.php/api/Hall/getOrderInfo',
    method: 'post',
    data: {
      order_sn
    }
  })
}

// 支付揭榜费用后的回调
export const MissionGetOrderInfo = ({
  order_sn
}) => {
  return request({
    url: '/index.php/api/Mission/getOrderInfo',
    method: 'post',
    data: {
      order_sn
    }
  })
}
