import axios from 'axios'
import store from '@/store'
import { Message, Loading } from 'element-ui'

// import router from '@/router'

// 创建一个自定的axios方法(比原axios多了个基地址)
// axios函数请求的url地址前面会被拼接基地址, 然后axios请求baseURL+url后台完整地址
let baseURL2 = ''
if (process.env.NODE_ENV === 'production') {
  baseURL2 = 'https://yecao.renewmanagementcity.com'
}
export const baseURL = baseURL2

const myAxios = axios.create({
  baseURL
})

let loading = null // 定义loading变量
// 开始 加载loading
const startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.8)'
  })
}
// 结束 取消loading加载
const endLoading = () => {
  loading.close()
}

let needLoadingRequestCount = 0 // 声明一个变量

const showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) { // 当等于0时证明第一次请求 这时开启loading
    startLoading()
  }
  needLoadingRequestCount++ // 全局变量值++
}

const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return // 小于等于0 证明没有开启loading 此时return
  needLoadingRequestCount-- // 正常响应后 全局变量 --
  if (needLoadingRequestCount === 0) { // 等于0 时证明全部加载完毕 此时结束loading 加载
    endLoading()
  }
}

// 白名单: 不需要携带token的api地址
const whiteAPIList = ['/api/reg', '/api/login', '/Loginp/pclogin']
const listAPI = ['/index.php/api/Mission/getOrderInfo', '/index.php/api/Hall/getOrderInfo', '/index.php/api/Vip_pc/getOrderInfo', '/index.php/api/Recharge/getOrderInfo', '/v1/chat/completions']
// 定义请求拦截器
myAxios.interceptors.request.use(function (config) {
  if (!whiteAPIList.includes(config.url)) {
    if (!listAPI.includes(config.url)) {
      showFullScreenLoading()
    }
    // 为请求头挂载 Authorization 字段
    config.headers.token = store.state.token
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

// 定义响应拦截器
myAxios.interceptors.response.use(function (response) {
  tryHideFullScreenLoading()
  // 响应状态码为 2xx 时触发成功的回调，形参中的 response 是“成功的结果”
  return response
}, function (error) {
  // 响应状态码不是 2xx 时触发失败的回调，形参中的 error 是“失败的结果”
  if (error.response.status === 401) {
    // 无效的 token
    Message.error(error.response.data.msg)
    // 把 Vuex 中的 token 重置为空，并跳转到登录页面
    store.commit('updateToken', '')
    tryHideFullScreenLoading()
    return
    // router.push('/')
  } else if (error.response.status === 400) {
    Message.error(error.response.data.msg)
    tryHideFullScreenLoading()
    return
  } else {
    Message.error('网络错误')
    tryHideFullScreenLoading()
  }
  return Promise.reject(error)
})

// 导出自定义的axios方法, 供外面调用传参发请求
export default myAxios
