<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less" scoped>
@import "/src/assets/assets.css";
</style>
