import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    redirect: '/home', // 默认显示首页的二级路由
    children: [
      {
        path: 'home',
        component: () => import('@/views/home')
      },
      {
        path: 'release', // 发布需求
        component: () => import('@/views/home/release')
      },
      {
        path: 'hall', // 任务大厅
        component: () => import('@/views/home/hall')
      },
      {
        path: 'programme', // 方案大厅
        component: () => import('@/views/home/programme')
      },
      {
        path: 'taskDetails', // 任务详情
        component: () => import('@/views/details/taskDetails')
      },
      {
        path: 'record', // vip开通记录
        component: () => import('@/views/details/record')
      },
      {
        path: 'mergePay', // 合并支付
        component: () => import('@/views/details/mergePay')
      },
      {
        path: 'mergeList', // 合并列表
        component: () => import('@/views/details/mergeList')
      },
      {
        path: 'purchase', // 购买详情
        component: () => import('@/views/details/purchase')
      },
      {
        path: 'programmeDetails', // 方案详情
        component: () => import('@/views/details/programmeDetails')
      },
      {
        path: 'Listusers', // 方案详情
        component: () => import('@/views/details/Listusers')
      },
      {
        path: 'newList', // 新任务榜
        component: () => import('@/views/home/newList')
      },
      {
        path: 'vip', // vip
        component: () => import('@/views/home/vip')
      },
      {
        path: 'enterpriseVip', // 企业vip
        component: () => import('@/views/home/enterpriseVip')
      },
      {
        path: 'releaseDetails', // 需求详情
        component: () => import('@/views/home/releaseDetails')
      },
      {
        path: 'modifyContent', // 修改需求
        component: () => import('@/views/home/modifyContent')
      },
      {
        path: 'naturalResources', // 上传资源
        component: () => import('@/views/home/naturalResources')
      },
      {
        path: 'chatAi', // 工具赋能
        component: () => import('@/views/home/chatAi')
      },
      {
        path: 'modifyResources', // 修改资源
        component: () => import('@/views/home/modifyResources')
      },
      {
        path: '/myRelease', // 我的
        redirect: 'infoCenter',
        component: () => import('@/views/release/myRelease'),
        children: [
          {
            path: '/infoCenter', // 用户中心
            component: () => import('@/views/release/infoCenter')
          },
          {
            path: '/accountNumber', // 账号设置
            component: () => import('@/views/release/accountNumber')
          },
          {
            path: '/wallet', // 钱包
            component: () => import('@/views/release/wallet')
          },
          {
            path: '/news', // 消息通知
            component: () => import('@/views/release/news')
          }
        ]
      }
    ]
  },
  {
    path: '/reg',
    component: () => import('@/views/register')
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  }
]

const router = new VueRouter({
  routes
})

// 无需验证token的页面
const whiteList = ['/home']

router.beforeEach((to, from, next) => {
  next() // 路由放行
  const token = store.state.token
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0

  if (token) {
    // 如果有token, 证明已登录
    if (!store.state.userInfo.username) {
      // 有token但是没有用户信息, 才去请求用户信息保存到vuex里
      // 调用actions里方法请求数据
      store.dispatch('initUserInfo')
      // 下次切换页面vuex里有用户信息数据就不会重复请求用户信息
    }
    next() // 路由放行
  } else {
    // 如果无token
    // 如果去的是白名单页面, 则放行
    store.commit('updateUserInfo', '')
    store.commit('isLogoChange', 1)
    if (whiteList.includes(to.path)) {
      next()
    } else {
      // 如果其他页面请强制拦截并跳转到登录页面
      // alert('请登录查看')
      // Message.error('请登录后操作')
      next()
    }
  }
})

export default router
